import React,{useState,useEffect} from 'react'
import classes from './Layout.module.css'
import Sidebar from './Sidebar'
import Lottie from 'lottie-react'
import Loader from '../Animations/loading.json'
// import Navbar from './Navbar'

const Layout = ({children}) => {

  const [loading,setLoading] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false)
      // document.getElementById(('contain').style.backgroundImage ='black');
    },2000)
  },[])
  return (
    <>
      {
    loading ? 
    <>
    <Lottie animationData={Loader} className={classes.animation}/>
    </>
    :
   
    <div id="contain" className={classes.container}>
   
   
    
    {children}
   

  
     
    </div>
   
      }
      </>
  )
}

export default Layout

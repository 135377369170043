import React from 'react'
import classes from './Footer.module.css'
import {useDispatch,useSelector} from 'react-redux';
import { setCategory } from '../Slices/categorySlice';


const Footer = () => {

  const dispatch = useDispatch();
  const {category} = useSelector((state)=>state.categorySlice)

  return (
    <div className={classes.container}>
    <div className={classes.sub_container}>
   <div className={classes.buttons}>
   <button className={classes.button} onClick={()=>{dispatch(setCategory("All Games"))}}>
   <img src="/assets/all.png" alt="" />
    <span className={`${category == "All Games" && classes.active}`}>All </span>
    </button>
    <button className={classes.button} onClick={()=>{dispatch(setCategory("New"))}}>
    <img src="/assets/new.png" alt="" />
    <span className={`${category == "New" && classes.active}`}>New</span>
    </button>
    <button className={classes.button} onClick={()=>{dispatch(setCategory("Quiz"))}}>
    <img src="/assets/quiz.png" alt="" />
    <span className={`${category == "Quiz" && classes.active}`}>Quiz</span>
    </button>
    <button className={classes.button} onClick={()=>{dispatch(setCategory("Fantasy Sports"))}}>
    <img src="/assets/fantasy.png" alt="" />
    <span className={`${category == "Fantasy Sports" && classes.active}`}>Fantasy </span>
    </button>
    <button className={classes.button} onClick={()=>{dispatch(setCategory("Games"))}}>
    <img src="/assets/games.png" alt="" />
    <span className={`${category == "Games" && classes.active}`}>Games</span>
    </button>
    <button className={classes.button} onClick={()=>{dispatch(setCategory("Videos"))}}>
    <img src="/assets/video.png" alt="" />
    <span className={`${category == "Videos" && classes.active}`}>Videos</span>
    </button>

    <button className={classes.button} onClick={()=>{dispatch(setCategory("AI"))}}>
    <img src="/assets/ai.png" alt="" />
    <span className={`${category == "AI" && classes.active}`}>AI</span>
    </button>
    
   </div>
    </div>
    </div>
  )
}

export default Footer

import React from 'react'
import classes from './Sidebar.module.css'
import SubLayout from './SubLayout'
import {useDispatch,useSelector} from 'react-redux';
import { setCategory } from '../Slices/categorySlice';


const Sidebar = () => {

  const dispatch = useDispatch();

  const {category} = useSelector((state)=>state.categorySlice)


  return (
    <>
      <div className={classes.container}>
      <div className={classes.box}>
      <div className={`${classes.icon} ${category == "All Games" && classes.active}`} onClick={()=>{dispatch(setCategory("All Games"))}}>
      <img src="/assets/all.gif" alt="" className={`${classes.icon_img}  ${category == "All Games" && classes.img_active} `} />
      <p>All</p>
      </div>
      <div className={`${classes.icon} ${category == "New" && classes.active}`} onClick={()=>{dispatch(setCategory("New"))}}>
      <img src="/assets/new.gif" alt="" className={`${classes.icon_img} ${category == "New" && classes.img_active} `} />
      <p>New</p>
      </div>
      <div className={`${classes.icon} ${category == "Games" && classes.active}`} onClick={()=>{dispatch(setCategory("Games"))}}>
      <img src="/assets/games.gif" alt="" className={`${classes.icon_img} ${category == "Games" && classes.img_active} `} />
      <p>Games</p>
      </div>
      <div className={`${classes.icon} ${category == "Quiz" && classes.active} `} onClick={()=>{dispatch(setCategory("Quiz"))}}>
      <img src="/assets/quiz.gif" alt="" className={`${classes.icon_img} ${category == "Quiz" && classes.img_active} `} />
      <p>Quizes</p>
      </div>
      <div className={`${classes.icon}  ${category == "Fantasy Sports" && classes.active}`} onClick={()=>{dispatch(setCategory("Fantasy Sports"))}}>
      <img src="/assets/fantasy.gif" alt="" className={`${classes.icon_img} ${category == "Fantasy Sports" && classes.img_active} `}/>
      <p>Fantasy Sports</p>
      </div>
      <div className={`${classes.icon} ${category == "Videos" && classes.active}`} onClick={()=>{dispatch(setCategory("Videos"))}}>
      <img src="/assets/video.gif" alt="" className={`${classes.icon_img} ${category == "Videos" && classes.img_active}  `} />
      <p>Videos</p>
      </div>
      <div className={`${classes.icon} ${category == "AI" && classes.active}`} onClick={()=>{dispatch(setCategory("AI"))}}>
      <img src="/assets/ai.gif" alt="" className={`${classes.icon_img} ${category == "AI" && classes.img_active} `} />
      <p>AI</p>
      </div>
     
      </div>
      </div>
    </>
  )
}

export default Sidebar
